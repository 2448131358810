// filtersSlug should be a set of key value pairs separated by a slash. Without the PLP slug
// e.g. make/audi/model/a3 NOT autos/make/audi/model/a3
const removeFiltersFromFiltersSlug = (filtersSlug: string, filtersToRemove: string[]) => {
  const filtersSlugParts = filtersSlug.split('/');

  filtersToRemove.forEach(filter => {
    if (filtersSlugParts.includes(filter)) {
      filtersSlugParts.splice(filtersSlugParts.indexOf(filter), 2);
    }
  });

  return filtersSlugParts.join('/');
};

export { removeFiltersFromFiltersSlug };
