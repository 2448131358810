import { useAtomValue, useAtom } from 'jotai';

import { postcodeAtom } from '@core/Atoms/Postcode/Postcode.atom';
import { aggregationsAtom } from '@core/Atoms/Search/AggregationsAtom';
import { locationAtom } from '@core/Atoms/Search/LocationAtom';
import { DEFAULT_DISTANCE } from '@core/Constants/Filters/LocationFilter.constants';
import { DEFAULT_SORTING } from '@core/Constants/Sort';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { getModelNameFromId } from '@core/Services/Search/Utils/GetModelNameFromId';

import { areaAtom } from '../Atoms/AreaAtom';
import { makeAtom } from '../Atoms/MakeAtom';
import { modelAtom } from '../Atoms/ModelAtom';
import { regionAtom } from '../Atoms/RegionAtom';
import { getPartsFromPlpPath } from '../Utils/GetPartsFromPlpPath';
import { removeFiltersFromFiltersSlug } from '../Utils/RemoveFiltersFromFiltersSlug';

const DISTANCES = {
  de: DEFAULT_DISTANCE['de'],
  fr: DEFAULT_DISTANCE['fr'],
  uk: undefined,
};

const useSearchCtaUrl = (
  postcodeRequired: boolean,
  locale: Locale,
  plpPath?: string,
  isLocationHub?: boolean,
) => {
  const [selectedMake] = useAtom(makeAtom);
  const [selectedModel] = useAtom(modelAtom);
  const { isValid, location, error: hasError } = useAtomValue(locationAtom);
  const postcode = useAtomValue(postcodeAtom);
  const { totalCount } = useAtomValue(aggregationsAtom);
  const isAnyMakeSelected = selectedMake && selectedMake.key !== 'any_make';
  const isAnyModelSelected = selectedModel && selectedModel.key !== 'any_model';
  const selectedRegion = useAtom(regionAtom);
  const selectedArea = useAtom(areaAtom);
  //plpPath used to be called plpURL. Was a sting with query params e.g 'ecom=true' or 'ecom=true&sort=price-asc'
  const isOldStylePlpPath = !!plpPath && plpPath.indexOf('/') !== 0;
  const cleanPlpPath = !plpPath || isOldStylePlpPath ? undefined : getPartsFromPlpPath(plpPath);

  const getMakeModelSlug = () => {
    const modelIdForSlug = getModelNameFromId(selectedModel.key);
    const makeKey = (isAnyMakeSelected && `/make/${selectedMake.key}`) || '';
    const modelKey = (isAnyModelSelected && `/model/${modelIdForSlug}`) || '';
    return makeKey + modelKey;
  };

  const getUserLocParams = (distance?: number): string => {
    // only check the lat and lon when API returns location
    if (isValid) {
      const { lat, lon } = location;
      const correctPC = postcode[locale];
      if (lat && lon && correctPC) {
        let queryString = `lat=${lat}&lon=${lon}&postcode=${correctPC}`;
        if (distance) {
          queryString += `&max-distance=${distance}`;
        }

        return queryString;
      }
    }
    return '';
  };

  const getLocationHubPath = () => {
    const keyName = locale === 'uk' ? 'key' : 'regionCode';
    if (selectedRegion[0].key !== 'any_region') {
      if (selectedArea[0].key !== 'any_area') {
        return `/${selectedRegion[0][keyName]}/${selectedArea[0].key}`;
      } else {
        return `/${selectedRegion[0][keyName]}`;
      }
    } else {
      return ``;
    }
  };

  const getPlpType = () => {
    if (isLocationHub) {
      return '/locations';
    } else if (cleanPlpPath) {
      return cleanPlpPath.typeSpecificFilters
        ? `/${cleanPlpPath.plpType}/${cleanPlpPath.typeSpecificFilters}`
        : `/${cleanPlpPath.plpType}`;
    } else {
      return '/autos';
    }
  };

  const filtersSlug = () => {
    if (isLocationHub) {
      return getLocationHubPath();
    }

    const makeModel = getMakeModelSlug();
    const plpPathFiltersSlug = cleanPlpPath?.filtersSlug
      ? removeFiltersFromFiltersSlug(cleanPlpPath?.filtersSlug, ['make', 'model'])
      : undefined;

    if (plpPathFiltersSlug) {
      return `${makeModel}/${plpPathFiltersSlug}`;
    }

    return makeModel;
  };

  const getQueryParams = () => {
    if (isLocationHub) {
      return '';
    }

    const userLocQueryStr = new URLSearchParams(getUserLocParams(DISTANCES[locale]));
    const plpPathQueryStr = new URLSearchParams(
      isOldStylePlpPath ? plpPath : cleanPlpPath?.queryStr,
    );

    // Sort can be overwritten from CMS
    if (!plpPathQueryStr.has('sort') && DEFAULT_SORTING[locale]) {
      plpPathQueryStr.append('sort', DEFAULT_SORTING[locale]!);
    }

    // User location data can NOT be overwritten from CMS
    plpPathQueryStr.delete('lat');
    plpPathQueryStr.delete('lon');
    plpPathQueryStr.delete('postcode');

    //if userLocQueryStr has lat it will always have lon and postcode
    if (userLocQueryStr.has('lat')) {
      plpPathQueryStr.append('lat', userLocQueryStr.get('lat')!);
      plpPathQueryStr.append('lon', userLocQueryStr.get('lon')!);
      plpPathQueryStr.append('postcode', userLocQueryStr.get('postcode')!);
    }
    //max-distance may or may not be in userLocQueryStr
    if (userLocQueryStr.has('max-distance'))
      plpPathQueryStr.append('max-distance', userLocQueryStr.get('max-distance')!);

    const queryStr = plpPathQueryStr.toString();
    return queryStr ? `?${queryStr}` : '';
  };

  const getSearchListingURL = () => {
    return `/${locale}${getPlpType()}${filtersSlug()}${getQueryParams()}`;
  };

  const getIsFormValid = () => {
    const isPostCodeValid = postcodeRequired ? !hasError && isValid : true;
    return isLocationHub ? true : totalCount > 0 && isPostCodeValid;
  };

  return { searchUrl: getSearchListingURL(), isFormValid: getIsFormValid() };
};

export { useSearchCtaUrl };
