import React from 'react';

import Button from '@gds/Button/Button';
import { areaAtom } from '@search/Atoms/AreaAtom';
import { regionAtom } from '@search/Atoms/RegionAtom';
import { shouldShowRegionLoaderAtom } from '@search/Atoms/RegionLoadingStateAtom';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/navigation';

interface ClearCTAProps {
  buttonText: string;
  disabled?: boolean;
  areaPlaceholder: string;
  regionPlaceholder: string;
}

export const ClearRegionAreaCta: React.FC<ClearCTAProps> = ({
  buttonText,
  disabled,
  areaPlaceholder,
  regionPlaceholder,
}) => {
  const setSelectedRegion = useSetAtom(regionAtom);
  const setSelectedArea = useSetAtom(areaAtom);
  const router = useRouter();
  const setShowLoader = useSetAtom(shouldShowRegionLoaderAtom);

  const handleClick = () => {
    setSelectedRegion({
      key: 'any_region',
      displayName: regionPlaceholder,
      region: 'anyregion',
      location: {
        lat: 0,
        lon: 0,
      },
      postcode: '',
      regionCode: '',
    });
    setSelectedArea({
      key: 'any_area',
      displayName: areaPlaceholder,
      region: 'anyarea',
      location: {
        lat: 0,
        lon: 0,
      },
      postcode: '',
      areaCode: '',
    });
    router.push('/dealerships');
    setShowLoader(true);
  };

  return (
    <Button
      fullWidth
      variant="outlined"
      aria-label="search-button"
      onClick={handleClick}
      disabled={disabled}
      type="submit"
      data-test-id="search-button"
    >
      {buttonText}
    </Button>
  );
};
