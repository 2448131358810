import { DEFAULT_LOCALE, DE_LOCALE, FR_LOCALE } from '@core/Constants/Locale.constants';
import { slugParamMapping } from '@core/Services/Search/Search.constants';

interface PlpPathParts {
  locale: string | undefined;
  plpType: string;
  typeSpecificFilters: string | undefined;
  filtersSlug: string | undefined;
  queryStr: string | undefined;
}

const getPartsFromPlpPath = (plpPath: string) => {
  const locales = [DE_LOCALE, FR_LOCALE, DEFAULT_LOCALE];
  const parts: PlpPathParts = {
    locale: undefined,
    plpType: 'autos',
    typeSpecificFilters: undefined,
    filtersSlug: undefined,
    queryStr: undefined,
  };

  const hasSlash = plpPath.startsWith('/');
  const searchParts = (hasSlash ? plpPath : `/${plpPath}`).split('?');
  const slashParts = searchParts[0].split('/');
  const hasLocale = locales.includes(slashParts[1]);
  const plpTypePos = hasLocale ? 2 : 1;
  let filtersSlugPos = plpTypePos + 1;

  if (hasLocale) {
    parts.locale = slashParts[1];
  }

  parts.plpType = slashParts[plpTypePos];

  if (parts.plpType === 'dealerships') {
    parts.typeSpecificFilters = slashParts[plpTypePos + 1];
  } else if (parts.plpType === 'locations') {
    // location PLPs will always have a region and possibly an area in the slug
    const region = slashParts[plpTypePos + 1];
    const possibleArea = slashParts[plpTypePos + 2];
    const hasRegionAndArea = !!possibleArea && !Object.hasOwn(slugParamMapping, possibleArea);
    parts.typeSpecificFilters = hasRegionAndArea ? `${region}/${possibleArea}` : region;
  }

  if (parts.typeSpecificFilters) {
    const typeSpecificFiltersParts = parts.typeSpecificFilters.split('/').length;
    filtersSlugPos = filtersSlugPos += typeSpecificFiltersParts;
  }

  if (slashParts[filtersSlugPos]) {
    parts.filtersSlug = slashParts.slice(filtersSlugPos).join('/');
  }

  if (searchParts[1]) {
    parts.queryStr = searchParts[1];
  }

  return parts;
};

export { getPartsFromPlpPath };
