import { atomWithStorage } from 'jotai/utils';

import { LocationState } from '../../Entities/Search/LocationState.entity';

import { createCookieStorage } from '../../Utils/CookieStorage';

const defaultValue = {
  isValid: false,
  location: {
    lat: 0,
    lon: 0,
  },
  error: false,
  placeName: '',
};

export const locationAtom = atomWithStorage<LocationState>(
  'locationValue',
  defaultValue,
  createCookieStorage<LocationState>(defaultValue),
);
