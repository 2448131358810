import { ReactNode, useMemo } from 'react';

import { aggregationsAtom } from '@core/Atoms/Search/AggregationsAtom';
import { MakeModelAtom } from '@core/Entities/Search/Atoms/MakeAtom.entity';
import { groupByField } from '@core/Utils/GroupByField/GroupByField';
import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';
import { RegionAreaAtom } from '@search/Atoms/RegionAtom.entity';
import { useAtomValue } from 'jotai';

import { locationAggregationsAtom } from 'Atoms/Search/LocationAggregationAtom/LocationAggregationAtom';
import { PageLoader } from 'Components/PageLoader/PageLoader';

import QuickSearch from '../QuickSearch/QuickSearch';

import { CustomDropDownProps } from './CustomDropDown.entity';

import { CustomDropDownItem } from './CustomDropDownItem/CustomDropDownItem';

import styles from './CustomDropDown.module.css';

const CustomDropDown = ({
  title = '',
  items,
  isGrouped = false,
  onSelect,
  checkedValue = '',
  dropdownType = 'make',
  dataTestId,
  searchPlaceholder,
  noResultText,
  handleSearchChange,
  handleMenuOpen,
  countLabel,
}: CustomDropDownProps) => {
  const { isLoading, error } = useAtomValue(
    dropdownType === 'region' ? locationAggregationsAtom : aggregationsAtom,
  );

  const defaultItem = (
    (items as (MakeModelAtom | RegionAreaAtom)[]).filter(
      (itm): itm is MakeModelAtom =>
        (itm as MakeModelAtom).make === 'anymake' ||
        (itm as MakeModelAtom).make === 'anymodel' ||
        (itm as RegionAreaAtom).region === 'anyregion' ||
        (itm as RegionAreaAtom).region === 'anyarea',
    ) as MakeModelAtom[] | RegionAreaAtom[]
  )[0];

  const handleChange = (value: MakeModelAtom) => {
    onSelect(value);
  };

  const groups = useMemo(() => {
    let unsortedItems: (MakeModelAtom | RegionAreaAtom)[] = [];
    let fieldType = 'make';
    if (dropdownType === 'region') {
      unsortedItems = (items as RegionAreaAtom[]).filter(
        (itm: RegionAreaAtom) => itm.region !== 'anyregion' && itm.region !== 'anyarea',
      );
      fieldType = dropdownType;
    } else {
      unsortedItems = (items as MakeModelAtom[]).filter(
        (itm: MakeModelAtom) => itm.make !== 'anymake' && itm.make !== 'anymodel',
      );
    }

    const sortedItems = unsortedItems
      .slice()
      .sort((a, b) => a.displayName.localeCompare(b.displayName));

    return groupByField(
      sortedItems,
      fieldType as 'key' | 'displayName',
      isGrouped,
      false,
      true,
      true,
    );
  }, [items, isGrouped]);

  const makeTitle = (label: ReactNode) => (
    <Typography variant="subheading1" Component="p" className={styles.heading}>
      {label}
    </Typography>
  );

  return (
    <div className={styles.customDropDown} data-test-id={dataTestId}>
      {isLoading && (
        <div className={styles.loading}>
          <PageLoader isPage={false} isAbsolute />
        </div>
      )}

      {title && !isLoading && (
        <Typography variant="subheading2" Component="p" className={styles.title}>
          {title} <Close onClick={handleMenuOpen} className={styles.closeIcon} />
        </Typography>
      )}
      {!isLoading && (
        <div className={styles.search}>
          <QuickSearch
            onChange={handleSearchChange}
            placeHolder={searchPlaceholder}
            dataTestId={`${dropdownType}-search-input`}
            dropdownType={dropdownType}
          />
        </div>
      )}

      {(error || (!isLoading && groups.length === 0)) && (
        <div className={styles.noResult}>{noResultText}</div>
      )}

      {defaultItem && (
        <section className={styles.customDropDownSection}>
          <ul>
            <li>
              <CustomDropDownItem
                list={defaultItem as MakeModelAtom | RegionAreaAtom}
                checkedValue={checkedValue}
                handleChange={handleChange}
                dropdownType={dropdownType}
                countLabel={countLabel}
              />
            </li>
          </ul>
        </section>
      )}

      {groups.length > 0 &&
        groups.map((group, groupIndex) => (
          <section key={`group_${groupIndex}`} className={styles.customDropDownSection}>
            {makeTitle(group.title)}
            <ul>
              {group.items.map(({ item }, index) => {
                return (
                  <li key={`group_${groupIndex}_list_${index}`}>
                    <CustomDropDownItem
                      list={item as MakeModelAtom | RegionAreaAtom}
                      checkedValue={checkedValue}
                      handleChange={handleChange}
                      dropdownType={dropdownType}
                      countLabel={countLabel}
                    />
                  </li>
                );
              })}
            </ul>
          </section>
        ))}
    </div>
  );
};

export default CustomDropDown;
